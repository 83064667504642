/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { FC, useCallback, useEffect, useState } from 'react';

import { GetStaticPaths, GetStaticProps } from 'next';
import { ArticleJsonLd } from 'next-seo';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { buildURL } from 'react-imgix';

import BreadCrumbs from '@/components/breadcrumbs/Breadcrumbs';
import ClipTeaserStacked from '@/components/clips/ClipsTeaserStacked';
import { Container, Heading, Paragraph, Text, useUIDispatch } from '@/components/design-system';
import { VideoPaywallBox } from '@/components/paywall';
import Main from '@/components/shared/Main';
import Sharebar from '@/components/shared/Sharebar';
import { Carousel } from '@/components/ui';
import { MoviePageMainContent } from '@/components/videos/MoviePageMainContent';
import CastAndCrew from '@/components/videos/run-hide-fight/CastAndCrew';
import Reviews from '@/components/videos/run-hide-fight/Reviews';
import { useAuth0 } from '@/context/auth';
import castData from '@/data/cast-crew.json';
import { appApi } from '@/graphql/app';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { APP_STORE_ID, SITE_URL, TOTP_CAST } from '@/lib/constants';
import { getUtmCookieData } from '@/lib/utm_cookie';
import {
  DefaultVideoPaywall,
  WhatIsAWomanPaywall,
  TheGreatestLiePaywall,
  TerrorOnThePrairiePaywall,
} from '@/marketing/links';
import { useVideo } from '@/request/video';
import { VideoMetadata } from '@/types/videos';
import { continentKeyToName, stripHtml } from '@/utils/helpers';
import { getStoryBoardURL } from '@/request/mux/storyboard';
import { getVideo, getVideoPage, getVideoURL } from '@/request/middleware-api';
import { ClipProps, VideoDataProps, VideoPageProps } from '@/types/middleware';

const MoviePlayer = dynamic(() => import('@/components/videos/MoviePlayer'), {
  ssr: false,
});
const ConsentForm = dynamic(() => import('@/components/av-player/ConsentForm'), { ssr: false });
const LocationNotAllowedNotice = dynamic(() => import('@/components/av-player/LocationNotAllowedNotice'), {
  ssr: false,
});

const VideoPage: FC<VideoPageProps> = ({ storyBoardURL, v4VideoData, slug }) => {
  const { isAuthenticated, loginWithRedirect, subscriber, accessToken } = useAuth0();
  const router = useRouter();

  const uiDispatch = useUIDispatch();
  const { mutate: mutateVideo } = useVideo(v4VideoData as unknown, slug);

  const [isOver18, setIsOver18] = useState(false);
  const [videoURL, setVideoURL] = useState<string | null>(null);
  const [data, setData] = useState<VideoDataProps | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const video = (data && data?.video) || null;

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        setIsLoading(true);
        const videoData = await getVideo(slug);
        if (!videoData) return;
        setData(videoData);

        const videoSrc = await getVideoURL(videoData?.video?.secureVideoURL, accessToken);
        if (!videoSrc) return;
        setVideoURL(videoSrc);
      } catch (error) {
        console.error('Error fetching video URL:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchVideo();
  }, [accessToken, slug]);

  const handlePaywallCtaClick = useCallback(() => {
    switch (video?.slug) {
      case 'the-greatest-lie-ever-sold':
        router.push(TheGreatestLiePaywall.href);
        break;

      case 'what-is-a-woman':
        router.push(WhatIsAWomanPaywall.href);
        break;

      case 'terror-on-the-prairie':
        router.push(TerrorOnThePrairiePaywall.href);
        break;

      default:
        router.push(DefaultVideoPaywall.href);
        break;
    }
  }, [video, router]);

  const handleLoginClick = useCallback(() => {
    loginWithRedirect({
      appState: { targetUrl: window.location.pathname },
    });
  }, [loginWithRedirect]);

  useEffect(() => {
    if (localStorage.getItem('isOver18')) {
      setIsOver18(true);
    }
  }, []);

  useEffect(() => {
    if (video) {
      const abTestData = getABTestAnalyticData();
      const utmCookieData = getUtmCookieData();

      window.__DW_Next_Bridge?.Analytics.logEvent('Segment Page', {
        ...abTestData,
        ...utmCookieData,
        title: video?.title,
      });
    }
  }, [video]);

  // Meta
  const absUrl = `${SITE_URL}/videos/${video?.slug}`;
  const description = video?.description ? stripHtml(video.description) : '';
  const metaImageUrl = video?.images?.thumbnail?.land
    ? buildURL(video?.images?.thumbnail?.land, { w: 1200, h: 675 })
    : '';
  const parselyPubDate = video?.createdAt ? new Date(video.createdAt).toISOString().split('.')[0] + 'Z' : '';

  // Content Ratings
  const rating = video?.matureRating;
  const ratingIsAllowed = (rating === 'TV-MA' && isOver18) || rating !== 'TV-MA';

  // Continent Playback
  const locationIsAllowed = video?.geoRestrictionStatus === 'ALLOWED';

  // Member access
  const videoIsAllowed = video?.availableFor?.includes(subscriber?.effectivePlan);

  // Conditional display logic
  const hasAuthAccess =
    isAuthenticated && subscriber?.effectivePlan && video?.availableFor?.includes(subscriber.effectivePlan);
  const showAccessDenied = !videoIsAllowed && locationIsAllowed;
  const showLocationNotAllowed = !locationIsAllowed;
  const showConsentForm = !showAccessDenied && !showLocationNotAllowed && !ratingIsAllowed;
  const showPlayer = !showAccessDenied && !showLocationNotAllowed && !showConsentForm;
  const showAvailableLocations = video?.allowedContinents
    ? video?.allowedContinents?.length !== 0 || video?.allowedCountryNames?.length !== 0
    : null;

  // Videodata
  const metadata = video?.metaData as VideoMetadata | null;

  const castCrew = castData[video?.slug] || null;

  const imageUrl =
    video?.slug === 'the-greatest-lie-ever-sold'
      ? '/images/the-greatest-lie-ever-sold.jpg'
      : video?.slug === 'my-dinner-with-trump'
      ? '/images/dinner_with_trump.jpg'
      : video?.images?.thumbnail?.land;

  return (
    <Main bg='#000'>
      <Head>
        <meta content='Video' name='parsely-section' />
        <meta content='' name='parsely-tags' />
        <meta content={parselyPubDate} name='parsely-pub-date' />
        <meta content={absUrl} name='parsely-link' />
        {metaImageUrl && <meta content={metaImageUrl} name='parsely-image-url' />}
        <title>{video?.title ?? ''}</title>
        <link href={absUrl} rel='canonical' />
        <meta content='website' property='og:type' />
        <meta content={description} name='description' />
        <meta content={video?.title ?? ''} property='og:title' />
        <meta content={description} property='og:description' />
        {metaImageUrl && <meta content={metaImageUrl} property='og:image' />}
        <meta content={absUrl} property='og:url' />
        <meta content={video?.title ?? ''} property='twitter:title' />
        <meta content={description} property='twitter:description' />
        <meta content='summary_large_image' name='twitter:card' />
        {metaImageUrl && <meta content={metaImageUrl} name='twitter:image' />}
        <meta content={`app-id=${APP_STORE_ID}, app-argument=${absUrl}`} name='apple-itunes-app' />
        <meta content={absUrl} property='al:ios:url' />
        <meta content={absUrl} property='al:android:url' />
        <meta content={absUrl} name='twitter:app:url:iphone' />
        <meta content={absUrl} name='twitter:app:url:ipad' />
        <meta content={absUrl} name='twitter:app:url:googleplay' />
      </Head>
      <ArticleJsonLd
        authorName={video?.author?.name}
        dateModified={video?.updatedAt ?? null}
        datePublished={video?.createdAt ?? null}
        description={description}
        images={[metaImageUrl]}
        publisherLogo={`${SITE_URL}/images/logo.png`}
        publisherName='The Daily Wire'
        title={video?.title ?? ''}
        url={absUrl}
      />

      <MoviePageMainContent>
        <div className='video-container' css={{ width: '100%', maxWidth: '960px' }}>
          <div
            css={(theme) => ({
              position: 'relative',
              minHeight: '150px',
              [theme.mq.desktop]: {
                minHeight: '300px',
              },
            })}
          >
            {showPlayer ? (
              <MoviePlayer
                mutateVideo={mutateVideo}
                previewImageUrl={video?.images?.thumbnail?.land}
                secureVideoURL={videoURL}
                storyBoardURL={storyBoardURL}
                video={video as unknown as VideoDataProps}
              />
            ) : (
              <>
                {showAccessDenied && !hasAuthAccess && !isLoading && (
                  <VideoPaywallBox
                    imageUrl={imageUrl}
                    isAuthenticated={isAuthenticated}
                    onLoginClick={handleLoginClick}
                    onPaywallCtaClick={handlePaywallCtaClick}
                    requiredAccess={video?.availableFor}
                    userPlanCode={subscriber?.effectivePlan}
                  />
                )}

                {showConsentForm && (
                  <ConsentForm
                    contentType='video'
                    onClose={() => uiDispatch({ type: 'CLOSE_VIDEO_OVERLAY' })}
                    onSuccess={() => setIsOver18(true)}
                  />
                )}

                {showLocationNotAllowed && !isLoading && (
                  <LocationNotAllowedNotice
                    contentType='video'
                    onClose={() => uiDispatch({ type: 'CLOSE_VIDEO_OVERLAY' })}
                  />
                )}
                {video?.videoURL === null && imageUrl && (
                  <div>
                    <img alt={video?.title} css={{ width: '100%', height: 'auto', display: 'block' }} src={imageUrl} />
                  </div>
                )}
              </>
            )}
          </div>

          <div
            css={(theme) => ({
              padding: '0 24px',
              marginTop: 24,
              [theme.mq.desktop]: {
                padding: 0,
              },
            })}
          >
            <Heading css={{ color: '#fff' }}>{video?.title}</Heading>
            <div
              css={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                color: theme.colors.gray20,
              })}
            >
              {video?.matureRating && (
                <Text
                  css={(theme) => ({
                    border: `1px solid ${theme.colors.gray60}`,
                    color: theme.colors.gray20,
                    padding: '.25rem .5rem',
                    borderRadius: '.25rem',
                    marginRight: '.5rem',
                    flexShrink: 0,
                  })}
                  variant='100'
                >
                  {video.matureRating}
                </Text>
              )}

              {video && showAvailableLocations && (
                <Text variant='100'>
                  Available in{' '}
                  {video?.allowedContinents?.map((continentKey, index) => (
                    <React.Fragment key={continentKey}>
                      {continentKeyToName(continentKey)}
                      {video?.allowedContinents.length !== index + 1 && ', '}
                    </React.Fragment>
                  ))}
                  {video?.allowedCountryNames?.map((countryName, index) => (
                    <React.Fragment key={countryName}>
                      {countryName}
                      {video?.allowedCountryNames.length !== index + 1 && ', '}
                    </React.Fragment>
                  ))}
                </Text>
              )}
            </div>

            {video?.description && (
              <Paragraph
                css={(theme) => ({
                  color: theme.colors.white,
                  marginTop: 8,
                  marginBottom: 24,
                  lineHeight: '1.5',
                })}
              >
                {video?.description}
              </Paragraph>
            )}

            <Sharebar author='' title={video?.title ?? ''} type='Video' url={`${SITE_URL}/videos/${video?.slug}/`} />
          </div>
        </div>
      </MoviePageMainContent>

      {v4VideoData?.extras?.length > 0 && (
        <div
          css={{
            paddingBottom: '5rem',
            marginTop: '3rem',
          }}
        >
          <Container>
            <Carousel columns={4} kicker='Extras' mode='dark' offsetControls='16:9' tabletColumns={3}>
              {v4VideoData?.extras?.map((clip) => (
                <div key={clip.id}>
                  {clip.type === 'ShowEpisode' && clip.showEpisode && (
                    <ClipTeaserStacked clip={clip.showEpisode as ClipProps} />
                  )}
                </div>
              ))}
            </Carousel>
          </Container>
        </div>
      )}

      {(castCrew || metadata?.reviews) && (
        <Container css={{ margin: '56px auto', maxWidth: '1000px !important' }}>
          {castCrew && <CastAndCrew cast={castCrew} />}
          {metadata?.reviews && (
            <>
              <div className='Watch__HorizontalGradient' style={{ margin: '40px auto' }} />
              <Reviews reviews={metadata.reviews} />
            </>
          )}
        </Container>
      )}

      {slug.includes('terror-on-the-prairie') && (
        <Container
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '5rem',
          }}
        >
          <div css={{ color: '#fff', padding: '0 24px', flexWrap: 'wrap' }}>
            <Heading variant='600'>{TOTP_CAST.title}</Heading>
            <div
              css={(theme) => ({
                display: 'grid',
                gridTemplateColumns: 'repeat(2,1fr)',
                gridGap: '32px',
                marginBottom: '56px',
                marginTop: '32px',

                [theme.mq.tablet]: { gridTemplateColumns: 'repeat(4,1fr)' },
              })}
            >
              {TOTP_CAST.data.map((crew) => (
                <div key={crew.name}>
                  <div
                    css={(theme) => ({
                      width: '100%',
                      maxWidth: '120px',
                      marginBottom: '1rem',

                      img: {
                        borderRadius: '50%',
                      },

                      [theme.mq.tablet]: {
                        maxWidth: '154px',
                      },
                    })}
                  >
                    <img
                      alt={crew.name}
                      className='img-object-fit-cover'
                      css={(theme) => ({
                        width: '125px',
                        height: '125px',
                        [theme.mq.tablet]: {
                          width: '154px',
                          height: '154px',
                        },
                      })}
                      src={crew.image}
                      style={{ objectPosition: 'top', objectFit: 'cover' }}
                    />
                  </div>
                  <Heading variant='400'>{crew.name}</Heading>
                  <Text css={{ marginTop: ' 4px', color: '#CCC' }} variant='200'>
                    {crew.role}
                  </Text>
                </div>
              ))}
            </div>
          </div>
          <div style={{ width: '30%' }}>
            <a href='https://www.mt.gov/' rel='noreferrer' target='_blank'>
              <img
                alt='Montana Logo'
                src='/images/terrorOnThePrairie/montana.png'
                style={{
                  width: '100%',
                  maxHeight: '180px',
                  objectFit: 'contain',
                }}
              />
            </a>
          </div>

          <div
            className='creditBlock'
            style={{
              textAlign: 'center',
              width: '100%',
              color: '#B33828',
              marginTop: '0px',
              fontFamily: 'Libre Franklin',
            }}
          >
            <p
              style={{
                marginTop: '50px',
                marginBottom: '50px',
                color: 'white',
              }}
            >
              THE DAILY WIRE presents a BONFIRE LEGEND in association with VOLTAGE PICTURES
              <br />
              a MICHAEL POLISH Picture
              <br /> TERROR ON THE PRAIRIE
              <br /> GINA CARANO COWBOY CERRONE TYLER FISCHER GABRIEL-KANE DAY-LEWIS
              <br />
              RHYS JACKSON BECKER MATTHIAS HUES SAMAIRE ARMSTRONG with HEATH FREEMAN and NICK SEARCY
              <br />
              Casting DAVID GUGLIELMO, CSA Music by DALAL & MAESA Special Make-Up Effects ILL-WILLED FX Costume Designer
              ALLYSON ADAMS
              <br />
              Production Designer ADAM DIETRICH Editor PAUL BUHL Director of Photography STEEVEN PETITTEVILLE, A.F.C.
              Associate Producer JOSIAH NELSON
              <br />
              Co-Producers BUZ WALLICK TRAVIS MILLS Executive Producers CARTER BOEHM WILLIAM LOWERY DANIELLE COX
              <br />
              Executive Producers BEN SHAPIRO CALEB ROBINSON JONATHAN DECKTER NICOLAS CHARTIER
              <br />
              Producers JEREMY BOREING GINA CARANO Produced by DALLAS SONNIER, p.g.a
              <br />
              AMANDA PRESMYK, p.g.a. Written by JOSIAH NELSON Directed by MICHAEL POLISH
            </p>
          </div>

          <div
            css={(theme) => ({
              width: '100%',
              display: 'grid',
              gap: '30px',
              gridTemplateColumns: '1fr 1fr 1fr',
              [theme.mq.tablet]: {
                width: '70%',
              },
            })}
          >
            <img
              alt='Daily Wire Logo'
              src='/images/terrorOnThePrairie/dailyWire.svg'
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
            />
            <img
              alt='Bonfire Legend Logo'
              src='/images/terrorOnThePrairie/bonfirelegend_white.png'
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
            />
            <img
              alt='Voltage Logo'
              src='/images/terrorOnThePrairie/voltage-logo.png'
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
            />
          </div>
        </Container>
      )}

      <div style={{ height: '50px' }} />
      <BreadCrumbs
        links={[<Link href='/'>DailyWire+</Link>, <Link href='/watch/movies'>Watch</Link>, <>{video?.title}</>]}
      />
    </Main>
  );
};

export default VideoPage;

export const getStaticProps: GetStaticProps = async (context) => {
  const slug = context.params?.slug as string;
  const storyBoardData = await getStoryBoardURL('video', slug);

  const v4VideoData = await getVideoPage(slug);

  // TODO: Create issue for necessitating this PUBLISHED check. Non-published
  // items should not return from the API alltogether
  if (!v4VideoData.video) {
    return {
      notFound: true,
      revalidate: 600,
    };
  }

  return {
    props: {
      storyBoardURL: storyBoardData?.video?.muxStoryBoardURL,
      v4VideoData,
      slug,
    },
    revalidate: 600, // 10 mins (in seconds)
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const { videos } = await appApi.getVideoSlugs({ first: 10 });

  const paths = videos.map((video) => {
    const slug = video?.slug as string;

    return { params: { slug } };
  });

  return { paths, fallback: 'blocking' };
};
