/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';

import Image from 'next/image';
import NextLink from 'next/link';

import { Heading } from '@/components/design-system';
import { ClipProps } from '@/types/middleware';

const ClipTeaserStacked: React.FC<ClipProps> = ({ clip }) => {
  return (
    <article>
      <NextLink href={`/clips/${clip.slug}`}>
        <a>
          <div
            css={{
              position: 'relative',
              flexShrink: 0,
              alignSelf: 'flex-start',
              lineHeight: 0,
              marginBottom: 8,
            }}
          >
            {!!clip.images && (
              <Image
                alt={clip.title || ''}
                css={{ borderRadius: '15px', border: ' 0.5px solid rgba(11, 11, 17, 0.11) !important' }}
                height={294}
                src={clip.images.thumbnail.land}
                width={522}
              />
            )}
          </div>
          <div css={{ marginTop: 16 }}>
            <Heading
              as='h3'
              css={() => ({
                marginTop: 8,
                color: '#949494',
                fontFamily: 'Libre Franklin',
                textAlign: 'center',
                fontSize: '14px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              })}
              variant='300'
            >
              {clip.title}
            </Heading>
          </div>
        </a>
      </NextLink>
    </article>
  );
};

export default ClipTeaserStacked;
